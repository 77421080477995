import * as React from "react";
import { withStores } from "stores";
import { SeparatorProps } from "components/flexComponents/Separator/typings";
import { EGDSSpacing, EGDSSpacingHr } from "@egds/react-core/spacing";

export const Separator = withStores("context")((props: SeparatorProps) => {
  const { templateComponent } = props;

  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }

  const { view, spacing } = templateComponent.config;

  if (view === "simple") {
    return (
      <EGDSSpacing padding={{ blockstart: spacing }}>
        <div data-testid="simple-separator" />
      </EGDSSpacing>
    );
  }

  return <EGDSSpacingHr blockMargin={spacing} />;
});

Separator.displayName = "Separator";

export default Separator;
